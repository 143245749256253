import "./src/styles/global.css"
import TopLayout from "./src/components/TopLayout";
import React from 'react';
import "prismjs/themes/prism-tomorrow.css";

const wrapPageElement = ({ element, props }) => (
  <TopLayout {...props}>{element}</TopLayout>
);

export { wrapPageElement }

import React, { Ref } from "react";

import { Link as GatsbyLink } from "gatsby";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";

interface InternalLinkProps extends MuiLinkProps {
  to: string;
  ref?: Ref<HTMLAnchorElement>;
}

const InternalLink = React.forwardRef<HTMLAnchorElement, InternalLinkProps>(
  (props, ref) => {
    return <MuiLink component={GatsbyLink} ref={ref} {...props} />;
  }
);

export default InternalLink;

interface Navigation {
  title: string;
  path: string;
  isPrimary: boolean;
}

const navigation: Navigation[] = [
  {
    title: "Home",
    path: "/",
    isPrimary: true,
  },
  {
    title: "Résume",
    path: "/cv",
    isPrimary: true,
  },
  {
    title: "Tech Blog",
    path: "/blogs",
    isPrimary: true,
  },
];

export default navigation;

import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { Typography } from "@mui/material"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }`

const QuadroidLogo = () => {
  const data = useStaticQuery(query)
  return (
    <div css={css`
                display: flex;
                margin-right: 15px;
              `}
    >
      <StaticImage
        alt="Logo"
        height={75}
        width={75}
        placeholder="blurred"
        src="../../../assets/images/logo.png" />
      <div css={css`margin: auto;`}>
        <Typography variant="h4" color="inherit" gutterBottom={false}>
          {data.site.siteMetadata.title}
        </Typography>
      </div>
    </div>)
}

export default QuadroidLogo

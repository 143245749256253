import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
import Header from "./Header"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Box from "@mui/material/Box"
import InternalLink from "./UI/Materials"
import Button from "@mui/material/Button"
import { CssBaseline, Typography, useMediaQuery, useTheme } from "@mui/material"
import navigation from "../data/navigation"

const query = graphql`
    query SiteTitleQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`

const Layout = ({ children, onDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery(query)
  const theme = useTheme()

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <React.Fragment>
      <CssBaseline />
      <Box align={xsDown ? "center" : "inherit"}>
        <div css={css`min-height: 100%;`}>
          <Box sx={{ display: { sm: 'block', md: 'none' } }}>
            <SwipeableDrawer anchor="right" open={menuOpen} onClose={() => setMenuOpen(false)} onOpen={() => setMenuOpen(true)}>
              <Box m={1}>
                {navigation.map(x => (
                  <Box key={x.title} m={1}>
                    <Button component={InternalLink} onClick={() => setMenuOpen(false)}
                      to={x.path}>
                      <Typography variant={x.isPrimary ? "h6" : "body1"}>
                        {x.title}
                      </Typography>
                    </Button>
                  </Box>
                ))}
              </Box>
            </SwipeableDrawer>
          </Box>
          <Header
            siteTitle={data.site.siteMetadata.title}
            onMenu={() => setMenuOpen(!menuOpen)}
            onDarkMode={onDarkMode}
          />
          <div css={css`margin-top: 0.5rem`}>
            <main>{children}</main>
          </div>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default Layout

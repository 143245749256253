import PropTypes from "prop-types"
import React from "react"
import Toolbar from "@mui/material/Toolbar"
import Slide from "@mui/material/Slide"
import { IconButton } from "@mui/material"
import InternalLink from "./UI/Materials"
import InvertColorsIcon from "@mui/icons-material/InvertColors"
import MenuIcon from "@mui/icons-material/Menu"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"

import QuadroidLogo from "./UI/QuadroidLogo"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import navigation from "../data/navigation"

const Header = ({ siteTitle, onMenu, onDarkMode }) => {
  const trigger = useScrollTrigger({ target: (typeof window !== `undefined` ? window : undefined) })
  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar color="primary" enableColorOnDark={true}>
          <Toolbar disableGutters={true}>
            <Button component={InternalLink} to="/" color="inherit" underline={"none"}>
              <QuadroidLogo siteTitle={siteTitle} />
            </Button>

            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
              {navigation.map(x => (
                <Button component={InternalLink} to={x.path} color={x.isPrimary ? "inherit" : "textSecondary"} key={x.title}>
                  <Box height={"75px"} alignItems="center" justifyContent="center" display="flex">
                    <Box marginTop={x.isPrimary ? "0" : "5px"}>
                      <Typography variant={x.isPrimary ? "h6" : "body1"}>
                        {x.title}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
              ))}
            </Box>

            <Box flexGrow={1} />
            <IconButton onClick={onDarkMode} color="inherit">
              <InvertColorsIcon fontSize="large" />
            </IconButton>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton onClick={onMenu} color="inherit">
                <MenuIcon fontSize="large" />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box height={89} />
    </React.Fragment>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
  onHeader: PropTypes.func,
  onMenu: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

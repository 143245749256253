import { createTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material/styles";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";

const getInitialIsDark = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("theming:isDark") !== null) {
      return localStorage.getItem("theming:isDark") === "true"
    } else {
      return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    }
  } else {
    return false
  }
}

const theme = responsiveFontSizes(createTheme(
  {
    typography: {
      h1: {
        fontSize: "3.5rem",
        marginBottom: "1rem",
      },
      h2: {
        fontSize: "2.75rem",
        marginBottom: "1rem",
      },
      h3: {
        fontSize: "2.25rem",
        marginBottom: "1rem",
      },
      h4: {
        fontSize: "2rem",
        marginBottom: "0.5rem",
      },
      h5: {
        fontSize: "1.75rem",
        marginBottom: "0.5rem",
      },
      h6: {
        fontSize: "1.5rem",
        fontWeight: "normal",
        marginBottom: "0.5rem",
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "1rem",
      },
      button: {
        textTransform: "none",
        fontSize: "2rem",
      },
    },
    spacing: 8,
    palette: {
      mode: "light",
      primary: {
        main: "#589691",
        contrastText: "#fff",
      },
    },
  }))
export default function TopLayout({ children }) {
  const [isDark, setIsDark] = useState(false)

  const newTheme = createTheme({
    ...theme,
    palette: {
      mode: (isDark ? "dark" : "light"),
      primary: {
        main: "#589691",
        contrastText: "#fff",
      },
      background: isDark ? {
        default: "#202020",
        paper: "#252525"
      } : {}
    }
  })

  useEffect(() => {
    setIsDark(getInitialIsDark())
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("theming:isDark", isDark.toString())
    }
  }, [isDark])

  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <Layout onDarkMode={() => setIsDark(!isDark)}>
        {children}
      </Layout>
    </ThemeProvider>
  )
}
